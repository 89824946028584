/* eslint-disable vue/no-deprecated-v-on-native-modifier */
<template>
  <div class="login-box">
    <div class="login-form-box">
      <p class="company-name">
        <img
          style="width:100%;"
          src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/account/icon.svg"
        />
      </p>

      <div
        class="mt-80 fz-14 text_primary fw-600 d-flex align-center flex-column"
      >
        <Tabs v-model:activeKey="activeKey" class="tabs" :tabBarGutter="22">
          <TabPane key="1" tab="账户密码登录">
            <div class="mt-32">
              <Form layout="inline" :model="formState">
                <div class="d-flex bdb">
                  <Icon
                    name="iconGroup353"
                    height="20"
                    width="20"
                    class="mt-6"
                  ></Icon>
                  <FormItem>
                    <a-input
                      class="input ml-16 mt-2"
                      placeholder="请输入账号"
                      v-model:value="formState.username"
                    >
                    </a-input>
                    <Icon
                      v-if="formState.username"
                      class="input-icon"
                      name="iconbianzu"
                      height="14"
                      width="14"
                      @click="formState.username = ''"
                    ></Icon>
                  </FormItem>
                </div>
                <div class="d-flex bdb mt-40">
                  <Icon
                    name="iconGroup354"
                    height="20"
                    width="20"
                    class="mt-6"
                  ></Icon>
                  <FormItem>
                    <a-input
                      class="input ml-16 mt-2"
                      placeholder="请输入密码"
                      type="password"
                      v-model:value="formState.password"
                      @keyup.enter="handleLogin"
                    >
                    </a-input>
                    <Icon
                      v-if="formState.password"
                      class="input-icon"
                      name="iconbianzu"
                      height="14"
                      width="14"
                      @click="formState.password = ''"
                    ></Icon>
                  </FormItem>
                </div>
              </Form>
            </div>
          </TabPane>

          <!-- 手机验证码登录 -->
          <TabPane key="2" tab="手机验证码登录">
            <div class="mt-32">
              <Form layout="inline" :model="formState">
                <div class="d-flex bdb">
                  <Icon
                    name="iconGroup353"
                    height="20"
                    width="20"
                    class="mt-6"
                  ></Icon>
                  <FormItem>
                    <a-input
                      class="input ml-16 mt-2"
                      placeholder="请输入手机号"
                      v-model:value="formState.phone"
                    >
                    </a-input>
                    <Icon
                      v-if="formState.phone"
                      class="input-icon"
                      name="iconbianzu"
                      height="14"
                      width="14"
                      @click="formState.phone = ''"
                    ></Icon>
                  </FormItem>
                </div>
                <div class="d-flex bdb mt-40">
                  <Icon
                    name="iconGroup354"
                    height="20"
                    width="20"
                    class="mt-6"
                  ></Icon>
                  <FormItem>
                    <a-input
                      class="input ml-16 mt-2"
                      placeholder="请输入验证码"
                      v-model:value="formState.smsCode"
                      @keyup.enter="handleLogin"
                    >
                    </a-input>
                    <Icon
                      v-if="formState.smsCode"
                      class="input-icon"
                      name="iconbianzu"
                      height="14"
                      width="14"
                      @click="formState.smsCode = ''"
                    ></Icon>
                  </FormItem>
                  <Button
                    class="ml-12"
                    type="primary"
                    :disabled="!codeDisabled"
                    @click="handleGetCode"
                    >{{ codeText }}</Button
                  >
                </div>
              </Form>
            </div>
          </TabPane>
        </Tabs>
        <!-- <p>账户密码登录</p>
        <p class="border mt-8"></p> -->
      </div>

      <!-- <a class="mt-8 align-self-end mr-70 fz-12 text_muted">忘记密码</a> -->

      <div
        class="login-btn"
        :class="{ disabled: !submitBtnActive }"
        @click="handleLogin"
      >
        登录
      </div>

      <ul class="bottom-ul">
        <!-- <li>帮助</li>
        <li>隐私</li>
        <li>条款</li> -->
      </ul>
      <p class="bottom-text">
        版权所有·杭州快驰科技有限公司 | © {{ new Date().getFullYear() }} All
        Rights Reserved
      </p>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed } from "vue";
import { Form, Tabs, Button, message } from "ant-design-vue";
import $await from "@/utils/await";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { smsCodeApi, mobileLoginApi, loginApi } from "@/apis/login";

export default {
  name: "Login",
  components: {
    Form,
    FormItem: Form.Item,
    Tabs,
    TabPane: Tabs.TabPane,
    Button
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // tab
    const activeKey = ref("1");

    // 验证码倒计时状态
    const countStatus = ref(false);
    // 倒计时数字
    const countTime = ref(60);
    // 验证码按钮文案
    const codeText = ref("获取验证码");
    // 验证码按钮是否是不可点击状态
    const codeDisabled = computed(() => {
      let reg = /^[1][0-9]{10}$/;
      return reg.test(formState.phone) && !countStatus.value;
    });
    // 手机登录按钮是否可点击
    const submitBtnActive = computed(() => {
      let reg = /^[1][0-9]{10}$/;
      let reg2 = /^[0-9]{6}$/;
      return (
        (activeKey.value === "2" &&
          reg.test(formState.phone) &&
          reg2.test(formState.smsCode)) ||
        (activeKey.value === "1" && formState.username && formState.password)
      );
    });

    const formState = reactive({
      username: "",
      password: "",
      phone: "",
      smsCode: ""
    });

    // 登录
    const handleLogin = async () => {
      let formData;

      if (activeKey.value === "1") {
        // 账号密码登录
        formData = {
          username: formState.username,
          password: formState.password
        };
      } else {
        // 手机验证码登录
        formData = {
          mobile: formState.phone,
          code: formState.smsCode
        };
      }
      if (route.query.redirect_url) {
        formData.redirect_url = route.query.redirect_url;
      }
      const [, res] =
        activeKey.value === "1"
          ? await $await(loginApi(formData))
          : await $await(mobileLoginApi(formData));
      if (res) {
        message.success("登录成功！");
        if (route.query.redirect_url) {
          window.location.href = route.query.redirect_url;
        } else {
          localStorage.setItem(
            "userName",
            activeKey.value === "1" ? formState.username : formState.phone
          );
          router.push("/select");
        }
      }
    };

    // 获取用户信息，如果能获取到则说明已登录状态，直接跳回
    const getUserInfo = async () => {
      const [err, res] = await $await(store.dispatch("user/_userInfo"));
      if (res) {
        window.location.href = res;
      } else {
        console.log(err);
      }
    };
    getUserInfo();

    // 获取验证码
    const handleGetCode = async () => {
      const [, code] = await $await(
        smsCodeApi({ mobile: formState.phone, message_type: "LOGIN" })
      );
      if (code === "验证码发送成功") {
        countStatus.value = true;
        let interval = setInterval(() => {
          if (countTime.value <= 1) {
            countStatus.value = false;
            clearInterval(interval);
            countTime.value = 60;
            codeText.value = "获取验证码";
          } else {
            countTime.value--;
            codeText.value = `再次获取(${countTime.value}s)`;
          }
        }, 1000);
      }
    };

    return {
      submitBtnActive,
      codeDisabled,
      formState,
      handleLogin,
      getUserInfo,
      codeText,
      activeKey,
      handleGetCode
    };
  }
};
</script>
<style lang="less" scoped>
.login-box {
  width: 100%;
  height: 100vh;
  background: url("../../assets/login_bg.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
  .logo {
    position: absolute;
    left: 40px;
    top: 40px;
    height: 40px;
    width: 113px;
  }
  .login-form-box {
    width: 400px;
    float: right;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    .company-name {
      width: 220px;
      height: 66px;
      margin-top: 120px;
      font-size: 22px;
      font-weight: 600;
      text-align: center;
    }
    .tabs {
      width: 260px;
    }
  }
  .border {
    width: 24px;
    height: 2px;
    background: #007aff;
  }
  .input {
    border: none;
    position: relative;
    background: #fff;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: #d4d4d4;
      font-size: 14px;
    }
  }

  .input-icon {
    position: absolute;
    right: 0;
    top: 2px;
  }
  .login-btn {
    height: 40px;
    width: 260px;
    color: #fff;
    background: #007aff;
    text-align: center;
    line-height: 40px;
    margin-top: 40px;
    border-radius: 20px;
    cursor: pointer;
  }
  .disabled {
    background: rgba(0, 122, 255, 0.3);
  }
  .bottom-ul {
    position: absolute;
    bottom: 49px;
    color: #999;
    font-size: 12px;
    display: flex;
    width: 150px;
    justify-content: space-around;
    color: #999;
  }
  .bottom-text {
    position: absolute;
    bottom: 16px;
    color: #999;
    font-size: 12px;
  }
}
</style>
<style lang="less">
.login-box {
  .ant-form-item-control {
    line-height: 1;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
  .ant-input {
    font-size: 16px;
  }
  .ant-tabs-top-bar {
    min-width: 400px;
  }
  .ant-btn-primary[disabled] {
    border: none !important;
    background: #e6e6e6 !important;
    color: #c2c8c6 !important;
  }
}
</style>
