/* eslint-disable vue/no-deprecated-v-on-native-modifier */
<template>
  <div class="login-form-box">
    <div class="company-name">
      <img
        style="width:160px;"
        src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/account/icon.svg"
      />
    </div>

    <div class="select-box">
      <p class="tip">欢迎登录服务市场！</p>
      <div
        v-for="item in selectList"
        :key="item.app_key"
        class="select-btn"
        @click="handleJump(item.url)"
      >
        {{ item.name }}
      </div>
    </div>

    <p class="bottom-text">
      版权所有·杭州快驰科技有限公司 | © {{ new Date().getFullYear() }} All
      Rights Reserved
    </p>
  </div>
</template>

<script>
import { ref } from "vue";
// import { message } from "ant-design-vue";
import $await from "@/utils/await";
import { userEndpoint } from "@/apis/login";

export default {
  name: "Select",
  components: {},
  setup() {
    const selectList = ref([]);

    const handleGetlist = async () => {
      let username = localStorage.getItem("userName");
      const [, res] = await $await(userEndpoint({ username: username }));
      if (res) {
        if (res.length === 1) {
          handleJump(res[0].url);
        } else {
          selectList.value = res;
        }
      }
    };

    const handleJump = url => {
      window.location.href = url;
    };

    handleGetlist();

    return {
      selectList,
      handleJump,
      handleGetlist
    };
  }
};
</script>
<style lang="less" scoped>
.login-form-box {
  height: calc(100vh);
  position: inherit;
  background: #fff;
  .select-box {
    width: 600px;
    margin: 0 auto;
    padding: 60px 0;
    .tip {
      color: #777;
      text-align: center;
      font-size: 14px;
    }
  }
  .company-name {
    padding: 16px 0 16px 60px;
    border-bottom: 1px solid #f1f1f1;
  }
  .select-btn {
    height: 60px;
    width: 500px;
    color: #fff;
    background: #007aff;
    text-align: center;
    line-height: 60px;
    margin: 40px;
    font-size: 20px;
    border-radius: 30px;
    cursor: pointer;
  }
  .bottom-text {
    position: absolute;
    color: #999;
    font-size: 12px;
    bottom: 16px;
    width: 100%;
    text-align: center;
  }
}
</style>
